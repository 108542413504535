import iconsPng from '@/assets/img/png/icons';
import { svgIconPaths } from '@/assets/svg/icons';
import CustomButton from '@/components/atoms/CustomButton';
import CustomTable from '@/components/molecules/CustomTable';
import LoadingPage from '@/components/templates/LoadingPage';
import SidebarItemViewTemplate from '@/components/templates/SidebarItemViewTemplate';
import appRoutes from '@/constants/routes/app';
import { useMemberCareSpecialistsForOrganizations } from '@/hooks/member-care/memberCare';
import { useOrganizationById } from '@/hooks/organization/organization';
import ContractsTable from '@/modules/organization/ContractsTable';
import HistoricalDataChart from '@/modules/organization/HistoricalDataChart';
import InviteOrganizationAdminModal from '@/modules/organization/InviteOrganizationAdminModal';
import OrganizationRoster from '@/modules/organization/OrganizationRoster';
import colors from '@/theme/colors';
import { TMemberCare } from '@/types/MemberCare.types';
import { ExternalLinkIcon } from '@chakra-ui/icons';
import { HStack, Image, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { createFileRoute, useNavigate } from '@tanstack/react-router';
import { useMemo } from 'react';

const COLUMNS: string[] = ['First Name', 'Last Name'];
type TSortingColumn = (typeof COLUMNS)[number];
const IMAGE_SIZE_PX = '200px';

const OrganizationPage = () => {
  const { organizationId } = Route.useParams();
  const navigate = useNavigate();

  const { isOpen, onClose, onOpen } = useDisclosure();

  const { isLoading, data: organizationData } = useOrganizationById(organizationId);

  const {
    isLoading: isLoadingOrgMemberCareSpecialists,
    data: organizationMemberCareSpecialists,
    isRefetching,
    pagination,
  } = useMemberCareSpecialistsForOrganizations(organizationId);

  const organizationMemberCareSpecialistsTableData = organizationMemberCareSpecialists?.map((memberCareSpecialist) => ({
    ...memberCareSpecialist,
    id: memberCareSpecialist.user_id,
  }));

  const data = { nodes: organizationMemberCareSpecialistsTableData ?? [] };

  const tableListItems = useMemo(
    () =>
      data.nodes.map((item: (typeof data.nodes)[number]) => {
        return {
          id: item.user_id,
          cells: [
            item.firstName ?? item.user_metadata?.firstName ?? '',
            item.lastName ?? item.user_metadata?.lastName ?? '',
          ],
        };
      }),
    [data.nodes],
  );

  if (isLoading || isLoadingOrgMemberCareSpecialists) return <LoadingPage />;

  return (
    <SidebarItemViewTemplate
      title={'Organization Details'}
      buttonLabel="Invite Organization Admin"
      onButtonClick={onOpen}
    >
      <VStack width={'100%'} gap={'40px'} overflowX={'clip'}>
        <HStack justify={'flex-start'} width={'100%'} marginTop={'20px'}>
          <CustomButton
            isTransparent
            iconBeforeLabelSvgPath={svgIconPaths.leftArrow}
            labelColor={colors.background.blueGray}
            labelHoverColor="extra.black"
            label="Back to Organizations"
            hoverBackgroundColor="transparent"
            borderHoverColor="transparent"
            onClick={() => navigate({ to: appRoutes.organizations })}
            labelStyle={{ marginBottom: '5px', fontSize: '16px' }}
          />
        </HStack>
        <HStack width={'100%'} flex={1} alignItems={'flex-start'} gap={'60px'}>
          <VStack justifyContent={'space-between'} alignItems={'flex-start'} gap={'30px'}>
            <HStack justifyContent={'space-between'} width={'100%'}>
              <Text variant={'loraSmallTitle'}>{organizationData?.details.name}</Text>
              <a
                target="_blank"
                href={`https://${organizationData?.details.website}`}
                style={{ fontSize: '18px', textDecoration: 'underline' }}
              >
                {organizationData?.details.website}...
                <ExternalLinkIcon margin={'4px'} marginTop={'0'} />
              </a>
            </HStack>
            <HStack gap={'30px'}>
              <VStack marginRight={'10px'}>
                <Image
                  src={organizationData?.logoUrl || iconsPng.lock}
                  width={IMAGE_SIZE_PX}
                  height={IMAGE_SIZE_PX}
                  alt="mc-profile"
                  borderRadius={'10px'}
                  objectFit={'cover'}
                />
              </VStack>
              <VStack alignItems={'flex-start'} gap={'20px'} flex={1}>
                <Text variant={'urbanistMedium'}>
                  Representative Name: <br />
                  <b>{`${organizationData?.details.representativeFullName}`}</b>
                </Text>
                <Text variant={'urbanistMedium'}>
                  Representative Email: <br />
                  <b>{`${organizationData?.details.representativeEmail}`}</b>
                </Text>
                <Text variant={'urbanistMedium'}>
                  Representative Phone:
                  <br />
                  <b>{`${organizationData?.details.representativePhoneNumber}`}</b>
                </Text>
                <Text variant={'urbanistMedium'}>
                  Signed Up / Invited:
                  <br />
                  <b>{`${organizationData?.users?.signedUp} / ${organizationData?.users.invited}`}</b>
                </Text>
              </VStack>
            </HStack>
          </VStack>
          <VStack flex={1}>
            <HStack justifyContent={'space-between'} width={'100%'} alignItems={'flex-start'}>
              <Text variant={'loraSmallTitle'}>Member Support Specialists</Text>
              <CustomButton
                label="Manage Member Care"
                onClick={() => {
                  navigate({ to: appRoutes.memberCare });
                }}
              />
            </HStack>
            <CustomTable<TMemberCare[], TSortingColumn>
              isLoading={isLoadingOrgMemberCareSpecialists}
              dataWithId={data.nodes}
              isRefetching={isRefetching}
              pagination={pagination}
              tableListItems={tableListItems}
              isSearching={false}
              noItemsMessage="No Member Support Specialists"
              columns={COLUMNS}
              tableWidthPercentage="100%"
              marginBottom="20px"
              customBaseUrl={appRoutes.memberCare}
              hasRowSelect
            />
          </VStack>
        </HStack>
        <InviteOrganizationAdminModal isOpen={isOpen} onClose={onClose} organizationId={organizationId} />
        <VStack width={'100%'}>
          <Text variant={'loraSmallTitle'}>Historical Data Chart</Text>
          <HistoricalDataChart data={organizationData?.historical.monthly} />
        </VStack>
        <VStack width={'100%'}>
          <ContractsTable organizationId={organizationId} />
        </VStack>
        <VStack width={'100%'}>
          <Text variant="loraSmallTitle">Members</Text>
          <OrganizationRoster organizationId={organizationId} />
        </VStack>
      </VStack>
    </SidebarItemViewTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_admin/organizations/$organizationId')({
  component: OrganizationPage,
});
