import { MIN_CHARS_TO_DO_SEARCH } from '@/constants/numberVars';
import { useTableColumnSort } from '@/hooks/useTableColumnSort';
import { TMemberCareOrganization } from '@/types/MemberCare.types';
import { useMemberCareOrganizations } from '@/hooks/member-care/memberCare';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Center, HStack, Input, InputGroup, InputLeftElement, Text, VStack } from '@chakra-ui/react';
import FilterSelectPopover from '@/components/molecules/FilterSelectPopover';
import { svgIconPaths } from '@/assets/svg/icons';
import SvgIcon from '@/components/atoms/SvgIcon';
import colors from '@/theme/colors';
import CustomTable from '@/components/molecules/CustomTable';
import CircleFillOutWithText from '@/components/molecules/WellnessStateCircle/CircleFillOutWithText';
import { getColorFromPercentage } from '@/utils/getColorFromPercentage';
import { useGenericFilterStore } from '@/store/useFilterSortStore';

const ORGANIZATIONS_COLUMNS = ['Organization Name', 'Members Count', 'Submitted Documents Count', 'Score'] as const;
export type TMemberCareOrganizationsSortingColumn = (typeof ORGANIZATIONS_COLUMNS)[number];

const SEARCH_TEXT_INIT = '';

const MemberCareOrganizationsTableDisplay = () => {
  const {
    filter: filterInStore,
    sort: sortInStore,
    setFilter: setFilterInStore,
    setSort: setSortInStore,
  } = useGenericFilterStore('organizations');

  const { sortingColumn, sortingDirection, updateSortingColumn } =
    useTableColumnSort<TMemberCareOrganizationsSortingColumn>({
      defaultSortingColumn: 'Score',
    });

  useEffect(() => {
    if (sortInStore?.sortingColumn !== sortingColumn || sortInStore.sortingDirection !== sortingDirection)
      setSortInStore({ sortingColumn, sortingDirection });
  }, [sortInStore?.sortingColumn, sortInStore?.sortingDirection, sortingColumn, sortingDirection]);

  const [localSearchText, setLocalSearchText] = useState(
    filterInStore?.searchText ? filterInStore?.searchText : SEARCH_TEXT_INIT,
  );

  const [committedSearchText, setCommittedSearchText] = useState(
    filterInStore?.searchText ? filterInStore?.searchText : SEARCH_TEXT_INIT,
  );

  const {
    data: organizations,
    pagination,
    isLoading,
    isRefetching,
  } = useMemberCareOrganizations({
    sortingColumn,
    sortingDirection,
    searchText: committedSearchText,
  });

  const searchIsActive = committedSearchText.length > 0;

  // Updates all the 'committed' states which are sent to the data-fetching hook `useMemberCareMembers`
  const submitLocalSearchForm = () => {
    setCommittedSearchText(localSearchText);

    pagination.getFirstPage();
  };

  const resetSearchForm = () => {
    setLocalSearchText(SEARCH_TEXT_INIT);
    setCommittedSearchText(SEARCH_TEXT_INIT);

    pagination.getFirstPage();

    setFilterInStore({
      searchText: SEARCH_TEXT_INIT,
    });
  };

  const searchSubmitButtonDisabled = localSearchText.length < MIN_CHARS_TO_DO_SEARCH;

  const organizationsDataWithId = organizations
    ? organizations.map((organization) => ({ ...organization, id: organization.id }))
    : [];

  const organizationsTableListItems = useMemo(
    () =>
      organizationsDataWithId.map((memberCareOrganization: TMemberCareOrganization) => {
        // in case old/deprecated data score is larger then 10, we need to multiply it by 10
        // Should not show up on production, but just in case
        const totalScoreX10 =
          memberCareOrganization?.score > 10 ? memberCareOrganization?.score : memberCareOrganization?.score * 10;

        return {
          id: memberCareOrganization.id,
          cells: [
            memberCareOrganization.name,
            memberCareOrganization.membersCount.toString(),
            memberCareOrganization.submittedDocumentsCount.toString(),
            !memberCareOrganization?.score ? (
              <Text variant={'urbanistSmallBold'} color="text.mediumGray">
                NO SCORE
              </Text>
            ) : (
              <HStack gap={'14px'} marginLeft={'5px'}>
                <CircleFillOutWithText
                  circleDiameter={25}
                  svgId={`svg-id-${memberCareOrganization.id}`}
                  thickness={4}
                  percentageFilled={totalScoreX10}
                  circleColor={getColorFromPercentage(totalScoreX10)}
                  showPercentageText={false}
                  boxShadow={'0px 3px 6px 0px #0E006224'}
                />
                <Text variant={'urbanistSemiBold'}>{totalScoreX10.toFixed()}</Text>
              </HStack>
            ),
          ],
        };
      }),
    [organizationsDataWithId],
  );

  const saveFiltersAndSortToStore = useCallback(() => {
    setFilterInStore({
      searchText: committedSearchText,
    });

    setSortInStore({ sortingColumn, sortingDirection });
  }, [committedSearchText, sortingColumn, sortingDirection]);

  return (
    <VStack width={'100%'}>
      <HStack justifyContent={'space-between'} width={'100%'}>
        <Text variant="loraTitle">My Organizations</Text>
        {/* Search by Organization name */}
        <FilterSelectPopover
          filterIconPath={svgIconPaths.magnifyingGlass}
          filterIconText="Search"
          contentWidth="260px"
          onFilterSubmit={submitLocalSearchForm}
          onFilterReset={resetSearchForm}
          submitButtonDisabled={searchSubmitButtonDisabled}
          isFilterActive={searchIsActive}
          popoverContent={
            <VStack marginBottom={'24px'} alignItems={'start'} width={'100%'}>
              <Text variant={'urbanistBold'} marginTop={'10px'}>
                Search By Organization Name
              </Text>

              <InputGroup marginTop={'10px'} flexDir={'column'}>
                <InputLeftElement>
                  <Center height={'100%'} marginTop={'18px'}>
                    <SvgIcon
                      iconPath={svgIconPaths.magnifyingGlass}
                      color={localSearchText.length > 0 ? colors.text.darkBlue : colors.text.mediumGray}
                    />
                  </Center>
                </InputLeftElement>
                <Input
                  className="custom-input"
                  backgroundColor={'white'}
                  placeholder="Search..."
                  _placeholder={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                  value={localSearchText}
                  onChange={(e) => setLocalSearchText(e.target.value)}
                  _disabled={{ opacity: 0.8, cursor: 'not-allowed' }}
                />
                <Text variant={'urbanistSmallBold'} color={'text.mediumGray'} padding={'5px'}>
                  Minimum 3 characters
                </Text>
              </InputGroup>
            </VStack>
          }
        />
      </HStack>
      <CustomTable<TMemberCareOrganization[], TMemberCareOrganizationsSortingColumn>
        isLoading={isLoading}
        dataWithId={organizationsDataWithId}
        isRefetching={isRefetching}
        pagination={pagination}
        tableListItems={organizationsTableListItems}
        isSearching={false}
        noItemsMessage="No organizations"
        columns={ORGANIZATIONS_COLUMNS}
        sortingColumn={sortingColumn}
        updateSortingColumn={updateSortingColumn}
        sortingDirection={sortingDirection}
        tableWidthPercentage="100%"
        customColumnGridPercentages={[35, 20, 25, 20]}
        customBaseUrl="/member-care/organizations"
        hasRowSelect
        onRowSelect={() => saveFiltersAndSortToStore()}
      />
    </VStack>
  );
};

export default MemberCareOrganizationsTableDisplay;
