import {
  TMemberCareScoreFilterBE,
  TMemberCareScoreFilterFE,
  TMemberCareScoreFilterLabelFE,
} from '@/types/MemberCare.types';
import { WELLNESS_STATE_LIMIT_DANGER, WELLNESS_STATE_LIMIT_OK } from './numberVars';
import { TServiceConfiguration } from '@/types/Organization.types';

export const APP_NAME = 'MyOmnia Backoffice';
export const APP_DESCRIPTION = 'Empowering Your Wholeness';
export const NONE_OF_THE_ABOVE_LABEL = 'None of the above';
export const MODAL_QUESTION_FORM_KEY = 'modal_form_key';

export const CLIENT_APP_SIGN_IN_URL = 'https://app.myomnia.health/sign-in';

export const EMAIL_REGEX =
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const USER_FILTER_BY_OPTIONS = ['Email', 'Organization', 'First name', 'Last name'] as const;
export const MEMBER_CARE_FILTER_BY_OPTIONS = ['Email', 'First name', 'Last name'] as const;
export const MEMBER_CARE_MEMBER_FILTER_BY_OPTIONS = ['First name', 'Last name', 'Organization'] as const;

export const OPTION_OTHER = 'Other (please specify)';
export const OPTION_OTHER_BE_VALUE = 'OTHER';

export const INCONCLUSIVE_LAST_LOGIN_TEXT = 'A long time ago...';

export const SexBeFe = {
  MALE: 'Male',
  FEMALE: 'Female',
  NOT_TO_SAY: 'Prefer not to say',
};

export const SexFeBe = {
  Male: 'MALE',
  Female: 'FEMALE',
  'Prefer not to say': 'NOT_TO_SAY',
};

export const SEX_OPTIONS = Object.keys(SexFeBe);

export const MEMBER_CARE_SCORE_FILTER_VALUES = ['all', 'red', 'yellow', 'green'] as const;
export const MEMBER_CARE_SCORE_FILTER_LABELS = ['All Scores', 'Red Zone', 'Yellow Zone', 'Green Zone'] as const;

export const MEMBER_CARE_SEARCH_BY_LABELS = ['Individual name', 'Organization'] as const;

export const MEMBER_CARE_SCORE_FILTER_LABEL_TO_VALUE: Record<TMemberCareScoreFilterFE, TMemberCareScoreFilterLabelFE> =
  {
    all: 'All Scores',
    red: 'Red Zone',
    yellow: 'Yellow Zone',
    green: 'Green Zone',
  };

export const MEMBER_CARE_SCORE_FILTER_VALUE_TO_LABEL: Record<TMemberCareScoreFilterLabelFE, TMemberCareScoreFilterFE> =
  {
    'All Scores': 'all',
    'Red Zone': 'red',
    'Yellow Zone': 'yellow',
    'Green Zone': 'green',
  };

export const MEMBER_CARE_SCORE_FILTER_FE_BE: Record<TMemberCareScoreFilterFE, TMemberCareScoreFilterBE | undefined> = {
  all: undefined,
  green: { fromScore: WELLNESS_STATE_LIMIT_OK / 10, toScore: 100 / 10 },
  yellow: { fromScore: WELLNESS_STATE_LIMIT_DANGER / 10, toScore: WELLNESS_STATE_LIMIT_OK / 10 },
  red: { fromScore: 1, toScore: WELLNESS_STATE_LIMIT_DANGER / 10 },
};

export const CALENDLY_LINK_PLACEHOLDER = 'https://calendly.com/john-doe';

export const MEMBER_NOTE_TYPES = ['PERSONAL', 'MEMBERCARE_TO_USER', 'USER_TO_MEMBERCARE'] as const;
export const TIMELINE_TYPES = ['assessment', 'screener', 'memberCareNote', 'selfNote', 'observation'] as const;

export const MEMBER_PROFILE_SECTION_VALUES = ['member', 'notes'] as const;
const MEMBER_PROFILE_SECTION_DISPLAY = ['MEMBER', 'NOTES'] as const;

export const MEMBER_PROFILE_SECTIONS = MEMBER_PROFILE_SECTION_VALUES.map((sectionValue, i) => ({
  value: sectionValue,
  display: MEMBER_PROFILE_SECTION_DISPLAY[i],
}));

export const HIGH_CONCERN_LABEL = 'High Concern';
export const SOME_CONCERN_LABEL = 'Some Concern';
export const LOW_CONCERN_LABEL = 'Low Concern';

export const NO_CHANGE_CONCERN_LABEL = 'No change';

export const MEMBERS_TAB_VALUE = 'members';
export const ORGANIZATIONS_TAB_VALUE = 'organizations';

export const ORGANIZATION_CONTRACT_SERVICE_TYPES = [
  'ADDITIONAL_PREPAID_THIRD_PARTY_SERVICES',
  'TRAVEL_AND_MISC_EXPENSES',
  'CUSTOM_WELLNESS_TOPIC_TRAINING_SESSION',
  'PEER_SUPPORT_PROGRAM_IMPLEMENTATION_SUPPORT',
  'PEER_SUPPORT_WORKSHOP',
  'PEER_SUPPORT_SPECIALIST_CERTIFICATION',
  'CONSULTATIVE_SESSIONS_FOR_PEER_SUPPORT_PROGRAM',
] as const;

export const ORGANIZATION_CONTRACT_SERVICE_COST_BASIS_UNITS = [
  'PER_BLOCK_OF_PRE_PAID_SESSIONS',
  'PER_ESTIMATED_COST',
  'PER_SESSION',
  'PER_MEMBER',
  'PER_ATTENDEE',
  'PER_HOUR',
] as const;

export const ORGANIZATION_CONTRACT_SERVICES: {
  type: (typeof ORGANIZATION_CONTRACT_SERVICE_TYPES)[number];
  costBasisUnit: (typeof ORGANIZATION_CONTRACT_SERVICE_COST_BASIS_UNITS)[number];
  configuration: TServiceConfiguration;
}[] = [
  {
    type: 'ADDITIONAL_PREPAID_THIRD_PARTY_SERVICES',
    costBasisUnit: 'PER_BLOCK_OF_PRE_PAID_SESSIONS',
    configuration: 'upfront',
  },
  { type: 'TRAVEL_AND_MISC_EXPENSES', costBasisUnit: 'PER_ESTIMATED_COST', configuration: 'upfront' },
  { type: 'CUSTOM_WELLNESS_TOPIC_TRAINING_SESSION', costBasisUnit: 'PER_SESSION', configuration: 'charge' },
  { type: 'PEER_SUPPORT_PROGRAM_IMPLEMENTATION_SUPPORT', costBasisUnit: 'PER_MEMBER', configuration: 'upfront' },
  { type: 'PEER_SUPPORT_WORKSHOP', costBasisUnit: 'PER_ATTENDEE', configuration: 'included' },
  { type: 'PEER_SUPPORT_SPECIALIST_CERTIFICATION', costBasisUnit: 'PER_ATTENDEE', configuration: 'upfront' },
  { type: 'CONSULTATIVE_SESSIONS_FOR_PEER_SUPPORT_PROGRAM', costBasisUnit: 'PER_HOUR', configuration: 'upfront' },
] as const;
