import { MEMBERS_TAB_VALUE } from '@/constants/stringVars';
import { TMemberCare, TMemberCareTabValue } from '@/types/MemberCare.types';
import { TUser } from '@/types/User.types';
import { create } from 'zustand';
import { persist, subscribeWithSelector } from 'zustand/middleware';

type TAppStoreState = {
  user: TUser | null;
  setUser: (user: TUser) => void;
  memberCare: TMemberCare | null;
  setMemberCare: (memberCare: TMemberCare) => void;
  memberCareMembersTab: TMemberCareTabValue;
  setMemberCareMembersTab: (newTab: TMemberCareTabValue) => void;
  reset: () => void;
};

const initialState = {
  user: null,
  memberCare: null,
  memberCareMembersTab: MEMBERS_TAB_VALUE as TMemberCareTabValue,
};

/**
 * NOTE: Example of a store that requires persistence and subscription.
 * State will persis for user even after page refresh.
 * Use this as a reference when creating a new store.
 */
export const useAppStore = create<TAppStoreState>()(
  subscribeWithSelector(
    persist(
      (set) => ({
        ...initialState,
        setUser: (user) => {
          set((state) => {
            return {
              ...state,
              user,
            };
          });
        },
        setMemberCare: (memberCare) => {
          set((state) => {
            return {
              ...state,
              memberCare,
            };
          });
        },
        setMemberCareMembersTab: (newTab) => {
          set((state) => {
            return {
              ...state,
              memberCareMembersTab: newTab,
            };
          });
        },
        reset: () => {
          set((state) => ({
            ...state,
            ...initialState,
          }));
        },
      }),
      { name: 'appStore' },
    ),
  ),
);
