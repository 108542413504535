import { UseMutateFunction, useMutation, useQuery } from '@tanstack/react-query';
import { usePagination } from '../usePagination';
import {
  TCreateOrganizationContract,
  TOrganization,
  TOrganizationAdminListItem,
  TOrganizationContract,
  TOrganizationSuperAdminExtended,
} from '@/types/Organization.types';
import { TCustomAxiosError } from '@/services/api';
import useToast from '../useToast';
import {
  organizationByIdGet,
  inviteOrganizationAdminPost,
  getAllOrganizations,
  createOrganizationPost,
  getOrganizationRoster,
} from '@/services/api/requests/organization';
import queryKeys from '@/constants/queryKeys';
import { TSortingParams } from '@/types';
import { TUser } from '@/types/User.types';
import {
  createOrganizationContract,
  editOrganizationContract,
  getOrganizationContracts,
} from '@/services/api/requests/contracts';

const useCreateOrganization = (
  onSuccess?: (organization: TOrganization) => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<TOrganization, TCustomAxiosError, { name: string }, unknown>;
} => {
  const toast = useToast();

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async ({ name }: { name: string }) => {
      const response = await createOrganizationPost({
        name,
      });
      return response;
    },
    onSuccess: (organization) => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Organization successfully created.',
      });

      onSuccess?.(organization);
    },
    onError: (error: TCustomAxiosError) => {
      toast(
        {
          status: 'error',
          title: 'Creating organization error',
          description: error?.errors[0]?.message,
        },
        error,
      );
    },
  });

  return { mutate, isPending, isError };
};

const useOrganizations = ({ searchText, sortingColumn, sortingDirection }: TSortingParams & { searchText: string }) => {
  return usePagination<TOrganizationAdminListItem[], { searchText: string }>({
    query: getAllOrganizations,
    queryKey: queryKeys.organization.allOrganizations,
    sortingColumn,
    sortingDirection,
    queryParams: {
      searchText,
    },
  });
};

const useOrganizationRoster = ({
  searchText,
  id,
  activeUsers,
}: TSortingParams & { searchText: string; id: string; activeUsers: boolean }) => {
  return usePagination<TUser[], { searchText: string }>({
    query: (page, queryParams) => getOrganizationRoster(id, page, activeUsers, queryParams),
    queryKey: [queryKeys.organization.organizationRoster, id, activeUsers ? 'active' : 'deactivated'],
    queryParams: {
      searchText,
    },
  });
};

const useOrganizationContracts = ({ id }: { id: string }) => {
  return usePagination<TOrganizationContract[], { searchText: string }>({
    query: (page) => getOrganizationContracts(id, page),
    queryKey: [queryKeys.organization.organizationContracts, id],
  });
};

const useCreateOrganizationContract = (
  onSuccess?: (newContract: TOrganizationContract) => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<
    TOrganizationContract,
    TCustomAxiosError,
    { organizationId: string; newContract: TCreateOrganizationContract },
    unknown
  >;
} => {
  const toast = useToast();

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async ({
      organizationId,
      newContract,
    }: {
      organizationId: string;
      newContract: TCreateOrganizationContract;
    }) => {
      const response = await createOrganizationContract(organizationId, newContract);
      return response.data;
    },
    onSuccess: (newContract) => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Organization Contract successfully created.',
      });

      onSuccess?.(newContract);
    },
    onError: (error: TCustomAxiosError) => {
      toast(
        {
          status: 'error',
          title: 'Contract creation error',
          description: error?.errors[0]?.message,
        },
        error,
      );
    },
  });

  return { mutate, isPending, isError };
};

const useEditOrganizationContract = (
  onSuccess?: (newContract: TOrganizationContract) => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<
    Partial<TOrganizationContract>,
    TCustomAxiosError,
    { contractId: string; editedContract: Partial<TOrganizationContract> },
    unknown
  >;
} => {
  const toast = useToast();

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async ({
      contractId,
      editedContract,
    }: {
      contractId: string;
      editedContract: Partial<TOrganizationContract>;
    }) => {
      const response = await editOrganizationContract(contractId, editedContract);
      return response.data;
    },
    onSuccess: (editedContract) => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Organization Contract successfully edited.',
      });

      onSuccess?.(editedContract);
    },
    onError: (error: TCustomAxiosError) => {
      toast(
        {
          status: 'error',
          title: 'Contract editing error',
          description: error?.errors[0]?.message,
        },
        error,
      );
    },
  });

  return { mutate, isPending, isError };
};

const useOrganizationById = (
  organizationId: string,
): {
  isLoading: boolean;
  isSuccess: boolean;
  data: TOrganizationSuperAdminExtended | undefined;
  isError: boolean;
  error: Error | null;
} => {
  const { isLoading, isSuccess, data, isError, error } = useQuery({
    queryKey: [queryKeys.organization.allOrganizations, organizationId],
    queryFn: async () => {
      const response = await organizationByIdGet({
        id: organizationId,
      });
      return response;
    },
  });

  return { isLoading, isSuccess, data, isError, error };
};

const useInviteOrganizationAdmin = (
  organizationId: string,
  onSuccess?: () => void,
): {
  isError: boolean;
  isPending: boolean;
  mutate: UseMutateFunction<string, TCustomAxiosError, { firstName: string; lastName: string; email: string }, unknown>;
} => {
  const toast = useToast();

  const { mutate, isPending, isError } = useMutation({
    mutationFn: async ({ firstName, lastName, email }: { firstName: string; lastName: string; email: string }) => {
      const response = await inviteOrganizationAdminPost({
        organizationId,
        firstName,
        lastName,
        email,
      });
      return response;
    },
    onSuccess: () => {
      toast({
        status: 'success',
        title: 'Success',
        description: 'Invitation successfully sent!',
      });

      onSuccess?.();
    },
    onError: (error: TCustomAxiosError) => {
      toast(
        {
          status: 'error',
          title: 'Invite error',
          description: error?.errors[0]?.message,
        },
        error,
      );
    },
  });

  return { mutate, isPending, isError };
};

export {
  useCreateOrganization,
  useOrganizations,
  useOrganizationById,
  useInviteOrganizationAdmin,
  useOrganizationRoster,
  useOrganizationContracts,
  useCreateOrganizationContract,
  useEditOrganizationContract,
};
