import { ITEMS_PER_PAGE } from '@/theme/components/table';
import { api, TCustomAxiosPaginationResponse } from '..';
import { TApiAssessment, TAssessment, TCreateAssessment } from '@/types/Assessment.types';
import { getFormattedAssessmentTemplate } from '@/utils/documentTemplatesFormatter';
const root = 'v1/assessments';

const endpoints = {
  assessment: `${root}/assessment`,
  assessmentCreate: `${root}/assessment/create`,
  assessmentForUser: `${root}/users`,
};

export const getAllAssessments = async (page: number): Promise<TCustomAxiosPaginationResponse<TApiAssessment[]>> => {
  const { data } = await api.get(endpoints.assessment, {
    params: {
      limit: ITEMS_PER_PAGE,
      page,
    },
  });

  return data;
};

export const createAssessmentPost = async ({ slug, title, type }: TCreateAssessment): Promise<TApiAssessment> => {
  const template = getFormattedAssessmentTemplate({ slug, title, type });

  const { data } = await api.post(endpoints.assessmentCreate, {
    data: template,
    slug,
    title,
    type,
  });

  const assessment: TApiAssessment = data.data;

  return assessment;
};

export const deleteAssessmentById = async ({ id }: { id: string }): Promise<any> => {
  const { data } = await api.delete(`${endpoints.assessment}/${id}`);

  return data;
};

export const updateAssessmentById = async ({ id, data }: { id: string; data: TAssessment }): Promise<any> => {
  const {
    type,
    slug,
    props: { title },
  } = data;

  const { data: responseData } = await api.put(`${endpoints.assessment}/${id}`, {
    title,
    slug,
    type,
    data,
  });

  return responseData;
};

export const getAssessmentById = async ({ id }: { id: string }): Promise<TApiAssessment> => {
  const { data } = await api.get(`${endpoints.assessment}/${id}`);

  const assessments = data.data;

  return assessments;
};
