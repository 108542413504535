import { TCreateOrganizationContract, TOrganizationContract } from '@/types/Organization.types';
import { api, TCustomAxiosPaginationResponse } from '..';
import { CONTRACTS_PER_PAGE } from '@/constants/numberVars';

const root = 'v1/contracts';

const endpoints = {
  organizationContracts: `${root}/organization`,
  contracts: `${root}`,
};

export const getOrganizationContracts = async (
  orgId: string,
  page: number,
): Promise<TCustomAxiosPaginationResponse<TOrganizationContract[]>> => {
  const params: { limit: number; page: number } = {
    limit: CONTRACTS_PER_PAGE,
    page,
  };

  const { data } = await api.get<TCustomAxiosPaginationResponse<TOrganizationContract[]>>(
    `${endpoints.organizationContracts}/${orgId}`,
    {
      params,
    },
  );

  return data;
};

export const createOrganizationContract = async (
  orgId: string,
  newContract: TCreateOrganizationContract,
): Promise<TCustomAxiosPaginationResponse<TOrganizationContract>> => {
  const { data } = await api.post<TCustomAxiosPaginationResponse<TOrganizationContract>>(
    `${endpoints.organizationContracts}/${orgId}`,
    newContract,
  );

  return data;
};

export const editOrganizationContract = async (
  contractId: string,
  editedContract: Partial<TOrganizationContract>,
): Promise<TCustomAxiosPaginationResponse<TOrganizationContract>> => {
  const { data } = await api.patch<TCustomAxiosPaginationResponse<TOrganizationContract>>(
    `${endpoints.contracts}/${contractId}`,
    editedContract,
  );

  return data;
};
