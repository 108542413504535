import { createFileRoute } from '@tanstack/react-router';
import AppPageTemplate from '@/components/templates/AppPageTemplate';
import { MAX_CONTAINER_WIDTH } from '@/constants/dimensions';
import TabButtons from '@/components/atoms/TabButtons';
import MemberCareOrganizationsTableDisplay from '@/modules/memberCare/MemberCareOrganizationsTableDisplay';
import MemberCareMembersTableDisplay from '@/modules/memberCare/MemberCareMembersTableDisplay';
import { Flex, VStack } from '@chakra-ui/react';
import { useAppStore } from '@/store/useAppStore';
import { MEMBERS_TAB_VALUE, ORGANIZATIONS_TAB_VALUE } from '@/constants/stringVars';

const MemberCareMembers = () => {
  const { memberCareMembersTab, setMemberCareMembersTab } = useAppStore();

  return (
    <AppPageTemplate>
      <VStack width={MAX_CONTAINER_WIDTH}>
        <Flex justify={'center'} width={'100%'} marginBottom={'20px'}>
          <TabButtons
            options={[
              {
                onClick: () => setMemberCareMembersTab(MEMBERS_TAB_VALUE),
                labelValue: MEMBERS_TAB_VALUE,
                labelDisplay: 'MEMBERS',
              },
              {
                onClick: () => setMemberCareMembersTab(ORGANIZATIONS_TAB_VALUE),
                labelValue: ORGANIZATIONS_TAB_VALUE,
                labelDisplay: 'ORGANIZATIONS',
              },
            ]}
            selectedOptionValue={memberCareMembersTab}
          />
        </Flex>

        <VStack display={memberCareMembersTab === MEMBERS_TAB_VALUE ? 'flex' : 'none'} width={'100%'}>
          <MemberCareMembersTableDisplay />
        </VStack>

        <VStack display={memberCareMembersTab === ORGANIZATIONS_TAB_VALUE ? 'flex' : 'none'} width={'100%'}>
          <MemberCareOrganizationsTableDisplay />
        </VStack>
      </VStack>
    </AppPageTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_member-care/member-care/members/')({
  component: MemberCareMembers,
});
