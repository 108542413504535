import { svgIconPaths } from '@/assets/svg/icons';
import CustomButton from '@/components/atoms/CustomButton';
import SvgIcon from '@/components/atoms/SvgIcon';
import ConfirmCancelButtons from '@/components/molecules/ConfirmationModal/ConfirmCancelButtons';
import CustomTable from '@/components/molecules/CustomTable';
import ModalContainer from '@/components/molecules/ModalContainer/ModalContainer';
import { ORGANIZATION_CONTRACT_SERVICES } from '@/constants/stringVars';
import {
  useCreateOrganizationContract,
  useEditOrganizationContract,
  useOrganizationContracts,
} from '@/hooks/organization/organization';
import colors from '@/theme/colors';
import {
  TCreateOrganizationContract,
  TOrganizationContract,
  TOrganizationContractService,
  TServiceConfiguration,
} from '@/types/Organization.types';
import { displayCapitalizedUnderscoredString } from '@/utils/stringUtils';
import { Checkbox, HStack, Input, Select, Text, VStack } from '@chakra-ui/react';
import { yupResolver } from '@hookform/resolvers/yup';
import { addDays, format, isBefore } from 'date-fns';
import React, { useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import * as yup from 'yup';

type Props = {
  organizationId: string;
};

// const nowDate = new Date().toISOString().split('T')[0];

const COLUMNS = ['Created By', 'Start Date', 'End Date', 'Number of Services', 'Edit'] as const;
type TSortingColumn = (typeof COLUMNS)[number];

const createEditOrganizationContractSchema = yupResolver(
  yup
    .object()
    .shape({
      startDate: yup.string().required('Start date is required.'),
      endDate: yup.string(),
      services: yup
        .array()
        .of(
          yup.object().shape({
            configuration: yup.string().nullable(),
            type: yup.string(),
            costBasisUnit: yup.string(),
            isEnabled: yup.boolean(),
            upfrontSelection: yup.number().when(['isEnabled', 'configuration'], {
              is: (isEnabled: boolean, configuration: string) => isEnabled && configuration === 'upfront',
              then: () => yup.number().required(),
              otherwise: () => yup.mixed().nullable(),
            }),
            charge: yup.number().when(['isEnabled', 'configuration'], {
              is: (isEnabled: boolean, configuration: string) => isEnabled && configuration === 'charge',
              then: () => yup.number().required(),
              otherwise: () => yup.mixed().nullable(),
            }),
            isIncluded: yup.boolean(),
          }),
        )
        .compact((v) => !v.isEnabled)
        .min(1, 'Minimum one service is required'),
    })
    .required(),
);

const ContractsTable = ({ organizationId }: Props) => {
  const {
    isLoading,
    isRefetching,
    data,
    pagination,
    refetch: refetchContracts,
  } = useOrganizationContracts({
    id: organizationId,
  });

  const [contractToDisplay, setContractToDisplay] = useState<TOrganizationContract>();
  const [isContractModalEditing, setIsContractModalEditing] = useState(false);

  const [contractModalOpen, setContractModalOpen] = useState(false);

  const closeModal = () => {
    setContractModalOpen(false);
    setIsContractModalEditing(false);
    setContractToDisplay(undefined);
  };

  const isCreatingNewContract = isContractModalEditing && !contractToDisplay;

  const methods = useForm({
    mode: 'onChange',
    resolver: createEditOrganizationContractSchema,
  });

  const { register, setValue, formState, handleSubmit, watch, reset: resetContractForm } = methods;
  const { errors, isLoading: isLoadingFormSubmit, isSubmitting, isValid, isDirty } = formState;

  const confirmButtonDisabled = useMemo(() => {
    if (isContractModalEditing) {
      return !isValid || !isDirty;
    }

    return !isValid;
  }, [isContractModalEditing, isValid, isDirty]);

  const startDateValue = watch('startDate');
  const endDateValue = watch('endDate');
  const startDateAfterOneDay = startDateValue ? addDays(new Date(startDateValue), 1) : undefined;

  const formServices = watch('services');

  const contracts = { nodes: data ?? [] };

  const tableListItems = useMemo(
    () =>
      contracts.nodes.map((contract: (typeof contracts.nodes)[number]) => {
        return {
          id: contract.id,
          cells: [
            contract.createdBy,
            format(new Date(contract.startDate), 'MMM-dd-yyyy'),
            format(new Date(contract.endDate), 'MMM-dd-yyyy'),
            (contract.services?.length ?? 0).toString(),
            <SvgIcon
              style={{ padding: '20px', marginLeft: '-20px' }}
              onClick={(event) => {
                event.stopPropagation();

                setIsContractModalEditing(true);
                setContractToDisplay(contract);

                const servicesToEdit = [...ORGANIZATION_CONTRACT_SERVICES];
                servicesToEdit.forEach((serviceToEdit, i) => {
                  const foundService = contract.services.find((service) => service.type === serviceToEdit.type);
                  if (foundService) {
                    servicesToEdit[i] = { ...servicesToEdit[i], ...foundService };
                  }
                });

                const contractToEdit = {
                  ...contract,
                  services: servicesToEdit,
                  startDate: contract.startDate.split('T')[0],
                  endDate: contract.endDate.split('T')[0],
                };

                resetContractForm({ ...contractToEdit });

                setContractModalOpen(true);
              }}
              iconPath={svgIconPaths.pen}
              size={22}
              boxHeight={15}
              boxWidth={15}
              color={colors.text.darkBlue}
              hoveredColor={colors.extra.red}
            />,
          ],
        };
      }),
    [contracts],
  );

  useEffect(() => {
    if (endDateValue && startDateAfterOneDay) {
      const endDateDate = new Date(endDateValue);
      if (isBefore(endDateDate, startDateAfterOneDay)) {
        setValue('endDate', startDateAfterOneDay.toISOString().split('T')[0]);
      }
    }
  }, [startDateAfterOneDay, endDateValue]);

  const resetModalAndContracts = () => {
    resetContractForm();
    setContractModalOpen(false);
    refetchContracts();
  };

  const { mutate: createNewContract, isPending: isContractCreationPending } =
    useCreateOrganizationContract(resetModalAndContracts);

  const { mutate: editContract, isPending: isContractEditPending } =
    useEditOrganizationContract(resetModalAndContracts);

  const onSubmit = (formContractData: any) => {
    const formServicesWithoutConfiguration = [
      ...formContractData.services.map(
        (service: TOrganizationContractService & { configuration: TServiceConfiguration }) => {
          const { configuration, ...restOfService } = service;
          if (configuration === 'upfront') {
            delete restOfService.charge;
            delete restOfService.isIncluded;
          }
          if (configuration === 'charge') {
            delete restOfService.upfrontSelection;
            delete restOfService.isIncluded;
          }
          if (configuration === 'included') {
            delete restOfService.upfrontSelection;
            delete restOfService.charge;
          }
          return restOfService;
        },
      ),
    ];

    if (isCreatingNewContract) {
      const newContract = {
        ...formContractData,
        services: formServicesWithoutConfiguration,
        organizationId,
      } as TCreateOrganizationContract;

      createNewContract({ organizationId, newContract });
    } else {
      const editedContract = {
        ...formContractData,
        services: formServicesWithoutConfiguration,
        organizationId,
      } as Partial<TCreateOrganizationContract> & {
        id?: string;
        createdBy?: string;
        createdAt?: string;
        updatedAt?: string;
        updatedBy?: string;
      };

      delete editedContract.id;
      delete editedContract.organizationId;
      delete editedContract.createdBy;
      delete editedContract.createdAt;
      delete editedContract.updatedAt;
      delete editedContract.updatedBy;

      editContract({ contractId: formContractData.id, editedContract });
    }
  };

  return (
    <VStack width={'100%'}>
      <HStack width={'90%'} justifyContent={'space-between'}>
        <Text variant="loraSmallTitle">Contracts</Text>
        <CustomButton
          label="Create a Contract"
          onClick={() => {
            setIsContractModalEditing(true);
            setContractToDisplay(undefined);

            resetContractForm({ services: ORGANIZATION_CONTRACT_SERVICES, startDate: '' });

            setContractModalOpen(true);
          }}
        />
      </HStack>

      <CustomTable<TOrganizationContract[], TSortingColumn>
        isLoading={isLoading}
        dataWithId={contracts.nodes}
        isRefetching={isRefetching}
        pagination={pagination}
        tableListItems={tableListItems}
        isSearching={false}
        noItemsMessage="No Contracts for this Organization"
        columns={COLUMNS}
        marginBottom="20px"
        customColumnGridPercentages={[30, 15, 15, 20, 20]}
        hasRowSelect
        onRowSelect={(contractId) => {
          setIsContractModalEditing(false);
          const newContractToDisplay = contracts.nodes.find((contract) => contract.id === contractId);

          if (newContractToDisplay) {
            setContractToDisplay(newContractToDisplay);

            setContractModalOpen(true);
          }
        }}
        navigateOnRowSelect={false}
      />

      <ModalContainer
        isOpen={contractModalOpen}
        onClose={closeModal}
        height={'30vh'}
        title={`${isContractModalEditing ? (isCreatingNewContract ? 'Create a Contract' : 'Editing a Contract') : 'Contract Summary'}`}
        titleFontSize="28px"
        width="45%"
        contentStyle={{ padding: '20px' }}
      >
        <VStack marginTop={'10px'}>
          {isContractModalEditing ? (
            <FormProvider {...methods}>
              <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
                <VStack spacing={'10px'} padding={'20px'} width={'100%'}>
                  {/* Start-End Date */}
                  <HStack
                    // width={'100%'}
                    gap={'20px'}
                    marginBottom={!startDateValue || errors.startDate ? '0' : '26px'}
                    alignItems={'flex-start'}
                  >
                    <VStack gap={0} alignItems={'flex-start'}>
                      <Text paddingLeft={'4px'} paddingBottom={'4px'} variant={'urbanistSemiBold'}>
                        Start Date
                      </Text>
                      <Input
                        type="date"
                        placeholder="Start Date"
                        className="custom-input"
                        _placeholder={{ color: 'text.mediumGray', fontWeight: '600', fontSize: 12 }}
                        color={'text.darkGray'}
                        {...register('startDate', { required: true })}
                      />
                      <Text color={'red.500'} fontSize={'14px'}>
                        {errors.startDate?.message ?? ''}
                      </Text>
                    </VStack>
                    <VStack gap={0} alignItems={'flex-start'}>
                      <Text paddingLeft={'4px'} paddingBottom={'4px'} variant={'urbanistSemiBold'}>
                        End Date
                      </Text>
                      <Input
                        type="date"
                        placeholder="End Date"
                        className="custom-input"
                        _placeholder={{ color: 'text.mediumGray', fontWeight: '600', fontSize: 12 }}
                        color={'text.darkGray'}
                        min={startDateAfterOneDay?.toISOString().split('T')[0]}
                        disabled={!startDateAfterOneDay}
                        {...register('endDate', { required: false })}
                      />
                      {!startDateValue && <Text variant={'finePrint'}>Please enter Start Date first</Text>}
                      <Text color={'red.500'} fontSize={'14px'}>
                        {errors.endDate?.message ?? ''}
                      </Text>
                    </VStack>
                  </HStack>

                  {/* Services */}
                  <VStack width={'100%'} alignItems={'flex-start'} gap={'40px'}>
                    {ORGANIZATION_CONTRACT_SERVICES.map((contractService, i) => (
                      <VStack key={contractService.type} alignItems={'flex-start'} gap={'10px'} width={'100%'}>
                        <HStack width={'100%'} justifyContent={'space-between'}>
                          <Text variant={'urbanistSemiBoldSmallTitle'}>
                            Type: {displayCapitalizedUnderscoredString(contractService.type)}
                          </Text>
                          <Select
                            {...register(`services.${i}.type`, { value: contractService.type })}
                            style={{ fontWeight: 600, backgroundColor: 'white', paddingRight: '16px' }}
                            icon={<></>}
                            display={'none'}
                          ></Select>
                          {/* Is Enabled */}
                          <HStack>
                            <Checkbox
                              {...register(`services.${i}.isEnabled`)}
                              colorScheme="white"
                              iconColor="text.mediumGray"
                              size={'lg'}
                              style={{ flexDirection: 'row-reverse', backgroundColor: 'white' }}
                            >
                              <Text
                                variant={'urbanistSemiBold'}
                                marginLeft={'-0.5rem'}
                                backgroundColor={'background.lightGray'}
                                borderColor={'background.lightGray'}
                                border={'1px solid background.lightGray'}
                                padding={'1px'}
                                paddingRight={'10px'}
                              >
                                Enabled:
                              </Text>
                            </Checkbox>
                          </HStack>
                        </HStack>
                        {/* Cost Unit Basis */}
                        <HStack>
                          <Text variant={'urbanistSemiBold'} minWidth={'110px'} textAlign={'start'}>
                            Cost Unit Basis:
                          </Text>

                          <Select
                            {...register(`services.${i}.costBasisUnit`, { value: contractService.costBasisUnit })}
                            style={{ fontWeight: 600, backgroundColor: 'white', paddingRight: '16px' }}
                            defaultValue={contractService.costBasisUnit}
                            icon={<></>}
                            _disabled={{ color: 'black', opacity: 1 }}
                            disabled
                          >
                            <option value={contractService.costBasisUnit}>
                              {displayCapitalizedUnderscoredString(contractService.costBasisUnit)}
                            </option>
                          </Select>
                        </HStack>

                        {/* Enabled-options */}
                        {formServices?.[i]?.isEnabled && (
                          <HStack width={'100%'} gap={'30px'}>
                            <HStack
                              display={contractService.configuration === 'upfront' ? 'flex' : 'none'}
                              width={'100%'}
                            >
                              <Text variant={'urbanistSemiBold'} minWidth={'120px'}>
                                Upfront Selection:
                              </Text>
                              <Input
                                type="number"
                                placeholder="Enter"
                                backgroundColor={'white'}
                                fontWeight={600}
                                fontSize={14}
                                _placeholder={{ color: 'text.mediumGray' }}
                                width={'120px'}
                                color={'text.darkGray'}
                                isInvalid={!!errors?.services?.[i]?.upfrontSelection}
                                min={0}
                                step={0.1}
                                {...register(`services.${i}.upfrontSelection`, {
                                  required: contractService.configuration === 'upfront',
                                })}
                              />
                              {errors?.services?.[i]?.upfrontSelection && (
                                <Text variant={'error'}>Please enter a number</Text>
                              )}
                            </HStack>

                            <HStack
                              display={contractService.configuration === 'charge' ? 'flex' : 'none'}
                              width={'100%'}
                            >
                              <Text variant={'urbanistSemiBold'}>Charge:</Text>
                              <Input
                                type="number"
                                placeholder="Enter"
                                backgroundColor={'white'}
                                fontWeight={600}
                                fontSize={14}
                                _placeholder={{ color: 'text.mediumGray' }}
                                width={'80px'}
                                color={'text.darkGray'}
                                isInvalid={!!errors?.services?.[i]?.charge}
                                min={0}
                                step={0.1}
                                {...register(`services.${i}.charge`, {
                                  required: contractService.configuration === 'charge',
                                })}
                              />
                              {errors?.services?.[i]?.charge && <Text variant={'error'}>Please enter a number</Text>}
                            </HStack>

                            <HStack
                              display={contractService.configuration === 'included' ? 'flex' : 'none'}
                              width={'100%'}
                            >
                              <Checkbox
                                {...register(`services.${i}.isIncluded`)}
                                colorScheme="white"
                                iconColor="text.mediumGray"
                                size={'lg'}
                                style={{ flexDirection: 'row-reverse', backgroundColor: 'white' }}
                              >
                                <Text
                                  variant={'urbanistSemiBold'}
                                  marginLeft={'-0.5rem'}
                                  backgroundColor={'background.lightGray'}
                                  borderColor={'background.lightGray'}
                                  border={'1px solid background.lightGray'}
                                  padding={'1px'}
                                  paddingRight={'10px'}
                                >
                                  Cost Included:
                                </Text>
                              </Checkbox>
                            </HStack>
                          </HStack>
                        )}
                      </VStack>
                    ))}
                  </VStack>

                  <ConfirmCancelButtons
                    confirmButtonText={isCreatingNewContract ? 'Create' : 'Save'}
                    onClose={closeModal}
                    confirmButtonType="submit"
                    confirmButtonDisabled={confirmButtonDisabled}
                    confirmLoading={
                      isLoadingFormSubmit || isSubmitting || isContractEditPending || isContractCreationPending
                    }
                  />
                  {formServices?.every((formService) => formService.isEnabled === false) && (
                    <Text variant={'error'} fontSize={'16px'} marginTop={'-20px'}>
                      You must enable at least one service
                    </Text>
                  )}
                </VStack>
              </form>
            </FormProvider>
          ) : (
            <VStack width={'100%'} alignItems={'flex-start'}>
              <Text variant={'urbanistSemiBoldSmallTitle'}>
                Duration:{' '}
                {contractToDisplay?.startDate ? format(new Date(contractToDisplay.startDate), 'MMM-dd-yyyy') : ''} to{' '}
                {contractToDisplay?.endDate ? format(new Date(contractToDisplay.endDate), 'MMM-dd-yyyy') : ''}
              </Text>
              <Text variant={'urbanistSemiBoldSmallTitle'} marginBottom={'20px'}>
                ID: {contractToDisplay?.id}
              </Text>

              <Text variant={'urbanistSemiBoldSmallTitle'}>
                Created: {format(new Date(contractToDisplay?.createdAt ?? 0), 'MMM-dd-yyyy')}
              </Text>
              <Text variant={'urbanistSemiBoldSmallTitle'}>By: {contractToDisplay?.createdBy}</Text>
              {/* {contractToDisplay?.createdAt && (
                <HStack>
                  <Text variant={'urbanistSemiBold'}>
                    On: {format(new Date(contractToDisplay.createdAt), 'MMM-dd-yyyy')}
                  </Text>
                </HStack>
              )}
              <Text variant={'urbanistSemiBold'}>By: {contractToDisplay?.createdBy}</Text> */}

              <Text variant={'urbanistSemiBoldSmallTitle'} marginTop={'20px'}>
                Updated:{format(new Date(contractToDisplay?.updatedAt ?? 0), 'MMM-dd-yyyy')}
              </Text>
              <Text variant={'urbanistSemiBoldSmallTitle'}>By: {contractToDisplay?.updatedBy}</Text>
              {/* {contractToDisplay?.updatedAt && (
                <HStack>
                  <Text variant={'urbanistSemiBold'}>
                    On: {format(new Date(contractToDisplay.updatedAt), 'MMM-dd-yyyy')}
                  </Text>
                </HStack>
              )}
              <Text variant={'urbanistSemiBold'}>By: {contractToDisplay?.updatedBy}</Text> */}

              <Text variant={'urbanistSemiBoldSmallTitle'} marginTop={'30px'} width={'100%'} textAlign={'center'}>
                Services: {contractToDisplay?.services.length ?? 0}
              </Text>
              {contractToDisplay?.services.map((service, i) => (
                <VStack key={service.type} alignItems={'flex-start'} marginY={'10px'}>
                  <Text variant={'urbanistSemiBold'}>
                    {i + 1}: {displayCapitalizedUnderscoredString(service.type)}
                  </Text>
                  <Text variant={'urbanistSemiBold'}>
                    Cost Basis Unit: {displayCapitalizedUnderscoredString(service.costBasisUnit ?? '')}
                  </Text>
                  {service?.charge && <Text variant={'urbanistSemiBold'}>Charge: {service.charge}</Text>}
                  {service?.upfrontSelection && (
                    <Text variant={'urbanistSemiBold'}>Upfront Selection: {service.upfrontSelection}</Text>
                  )}
                  {service?.isIncluded && (
                    <Text variant={'urbanistSemiBold'}>Included: {service.isIncluded ? 'Yes' : 'No'}</Text>
                  )}
                </VStack>
              ))}

              <HStack width={'100%'} justifyContent={'center'}>
                <CustomButton
                  label={'Close'}
                  labelColor="extra.white"
                  labelHoverColor="extra.white"
                  // backgroundColor={confirmButtonColor}
                  labelStyle={{ fontSize: '16px' }}
                  onClick={closeModal}
                  style={{ marginTop: '20px', minWidth: '110px' }}
                  height={'60px'}
                />
              </HStack>
            </VStack>
          )}
        </VStack>
      </ModalContainer>
    </VStack>
  );
};

export default ContractsTable;
