import { createFileRoute } from '@tanstack/react-router';
import { Center, HStack, Input, InputGroup, InputLeftElement, Text, useDisclosure, VStack } from '@chakra-ui/react';
import { getOrganizations } from '@/utils/queryOptions';

import LoadingPage from '@/components/templates/LoadingPage';
import { useOrganizations } from '@/hooks/organization/organization';
import CreateOrganizationModal from '@/modules/organization/CreateOrganizationModal';
import SidebarItemViewTemplate from '@/components/templates/SidebarItemViewTemplate';
import { useMemo, useState } from 'react';
import CustomTable from '@/components/molecules/CustomTable';
import { TOrganizationAdminListItem } from '@/types/Organization.types';
import FilterSelectPopover from '@/components/molecules/FilterSelectPopover';
import { svgIconPaths } from '@/assets/svg/icons';
import SvgIcon from '@/components/atoms/SvgIcon';
import { MIN_CHARS_TO_DO_SEARCH } from '@/constants/numberVars';
import colors from '@/theme/colors';
import { useTableColumnSort } from '@/hooks/useTableColumnSort';

const COLUMNS: string[] = [
  'Name',
  'Website',
  'Representative Email',
  'Total # of Employees',
  '# of Onboarded',
  '# of Member Cares',
];
export type TAdminOrganizationsSortingColumn = (typeof COLUMNS)[number];

const SEARCH_TEXT_INIT = '';

const Organizations = () => {
  const { isOpen, onClose, onOpen } = useDisclosure();

  const { sortingColumn, sortingDirection, updateSortingColumn } = useTableColumnSort<TAdminOrganizationsSortingColumn>(
    {
      defaultSortingColumn: 'Score',
    },
  );

  const [localSearchText, setLocalSearchText] = useState(SEARCH_TEXT_INIT);
  const [committedSearchText, setCommittedSearchText] = useState(SEARCH_TEXT_INIT);
  const searchIsActive = committedSearchText.length > 0;
  const searchSubmitButtonDisabled = localSearchText.length < MIN_CHARS_TO_DO_SEARCH;

  const {
    isLoading,
    data: organizationsResponse,
    pagination,
    isRefetching,
  } = useOrganizations({
    sortingColumn,
    sortingDirection,
    searchText: committedSearchText,
  });

  const organizationsTableData = organizationsResponse?.map((organization) => ({
    ...organization,
    id: organization._id,
  }));

  const submitLocalSearchForm = () => {
    setCommittedSearchText(localSearchText);
    pagination.getFirstPage();
  };

  const resetSearchForm = () => {
    setLocalSearchText(SEARCH_TEXT_INIT);
    setCommittedSearchText(SEARCH_TEXT_INIT);

    pagination.getFirstPage();
  };

  const data = { nodes: organizationsTableData ?? [] };

  const tableListItems = useMemo(
    () =>
      data.nodes.map((item: (typeof data.nodes)[number]) => {
        return {
          id: item._id,
          cells: [
            item.name,
            item.website ?? '',
            item.representativeEmail ?? '',
            <Text marginLeft={'40px'} fontWeight={'bold'}>
              {item.totalUsersCount}
            </Text>,
            <Text marginLeft={'40px'} fontWeight={'bold'}>
              {item.onboardedUsersCount}
            </Text>,
            <Text marginLeft={'40px'} fontWeight={'bold'}>
              {item.assignedMemberCaresCount}
            </Text>,
          ],
        };
      }),
    [data.nodes],
  );

  if (isLoading) return <LoadingPage />;

  return (
    <SidebarItemViewTemplate title={'Organizations'} buttonLabel="Create New Organization" onButtonClick={onOpen}>
      <VStack width={'100%'} marginTop={'20px'}>
        <HStack width={'100%'} justifyContent={'flex-end'}>
          <FilterSelectPopover
            filterIconPath={svgIconPaths.magnifyingGlass}
            filterIconText="Search"
            contentWidth="260px"
            onFilterSubmit={submitLocalSearchForm}
            onFilterReset={resetSearchForm}
            submitButtonDisabled={searchSubmitButtonDisabled}
            isFilterActive={searchIsActive}
            popoverPlacement="bottom-start"
            popoverContent={
              <VStack marginBottom={'24px'} alignItems={'start'} width={'100%'}>
                <Text variant={'urbanistBold'} marginTop={'10px'}>
                  Search By Name, Website or Representative Email
                </Text>

                <InputGroup marginTop={'10px'} flexDir={'column'}>
                  <InputLeftElement>
                    <Center height={'100%'} marginTop={'18px'}>
                      <SvgIcon
                        iconPath={svgIconPaths.magnifyingGlass}
                        color={localSearchText.length > 0 ? colors.text.darkBlue : colors.text.mediumGray}
                      />
                    </Center>
                  </InputLeftElement>
                  <Input
                    className="custom-input"
                    backgroundColor={'white'}
                    placeholder="Search..."
                    _placeholder={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                    value={localSearchText}
                    onChange={(e) => setLocalSearchText(e.target.value)}
                    _disabled={{ opacity: 0.8, cursor: 'not-allowed' }}
                  />
                  <Text variant={'urbanistSmallBold'} color={'text.mediumGray'} padding={'5px'}>
                    Minimum 3 characters
                  </Text>
                </InputGroup>
              </VStack>
            }
          />
        </HStack>
        <CustomTable<TOrganizationAdminListItem[], TAdminOrganizationsSortingColumn>
          isLoading={isLoading}
          dataWithId={data.nodes}
          isRefetching={isRefetching}
          pagination={pagination}
          tableListItems={tableListItems}
          isSearching={false}
          noItemsMessage="No organizations created yet"
          columns={COLUMNS}
          sortingColumn={sortingColumn}
          updateSortingColumn={updateSortingColumn}
          sortingDirection={sortingDirection}
          hasRowSelect
          fullWidth
          marginTop={'8px'}
        />
      </VStack>
      <CreateOrganizationModal isOpen={isOpen} onClose={onClose} />
    </SidebarItemViewTemplate>
  );
};

export const Route = createFileRoute('/_authenticated/_admin/organizations/')({
  component: Organizations,
  loader: async ({ context }) => context.queryClient.ensureQueryData(getOrganizations()),
});
