import { TFilterParams, TSortingParams } from '@/types';
import { create } from 'zustand';
import { subscribeWithSelector } from 'zustand/middleware';

type TPartialFilterParams = Partial<TFilterParams> | undefined | null;
type TPartialSortingParams = Partial<TSortingParams> | undefined | null;

type TFilterSortStoreState = {
  filter: TPartialFilterParams;
  sort: TPartialSortingParams;
  setFilter: (filter: TPartialFilterParams) => void;
  setSort: (sort: TPartialSortingParams) => void;
  resetFilter: () => void;
  resetSort: () => void;
};

const initialState = {
  filterKeyStore: null,
};

type TFilterSortKeyStoreState = {
  filterKeyStore: Record<string, { filter?: TPartialFilterParams; sort?: TPartialSortingParams }> | undefined | null;
  setFilter: (key: string) => (filter: TPartialFilterParams) => void;
  setSort: (key: string) => (sort: TPartialSortingParams) => void;
  resetFilter: (key: string) => () => void;
  resetSort: (key: string) => () => void;
  reset: () => void;
};

export const useTrueGenericFilterSortStore = create<TFilterSortKeyStoreState>()(
  subscribeWithSelector((set) => ({
    ...initialState,
    setFilter: (key) => (newFilter) => {
      set((state) => {
        return {
          ...state,
          filterKeyStore: {
            ...state.filterKeyStore,
            [key]: { ...state.filterKeyStore?.[key], filter: { ...state.filterKeyStore?.[key]?.filter, ...newFilter } },
          },
        };
      });
    },
    setSort: (key) => (newSort) => {
      set((state) => {
        return {
          ...state,
          filterKeyStore: {
            ...state.filterKeyStore,
            [key]: { ...state.filterKeyStore?.[key], sort: { ...state.filterKeyStore?.[key]?.sort, ...newSort } },
          },
        };
      });
    },
    resetFilter: (key) => () => {
      set((state) => ({
        ...state,
        filterKeyStore: { ...state.filterKeyStore, [key]: { ...state.filterKeyStore?.[key], filter: null } },
      }));
    },
    resetSort: (key) => () => {
      set((state) => ({
        ...state,
        filterKeyStore: { ...state.filterKeyStore, [key]: { ...state.filterKeyStore?.[key], sort: null } },
      }));
    },
    reset: () => {
      set((state) => ({
        ...state,
        ...initialState,
      }));
    },
  })),
);

export const useGenericFilterStore = (storeKey: string): TFilterSortStoreState => {
  const trueGenericStore = useTrueGenericFilterSortStore();

  return {
    filter: trueGenericStore.filterKeyStore?.[storeKey]?.filter,
    sort: trueGenericStore.filterKeyStore?.[storeKey]?.sort,
    resetFilter: trueGenericStore.resetFilter(storeKey),
    resetSort: trueGenericStore.resetSort(storeKey),
    setFilter: trueGenericStore.setFilter(storeKey),
    setSort: trueGenericStore.setSort(storeKey),
  };
};
